import { useMutation } from "@apollo/react-hooks";
import { notification, Spin } from "antd";
import { navigate } from "gatsby";
import idx from "idx";
import React, { FC, useCallback } from "react";
import CREATE_USER from "./createUser.graphql";
import {
  CreateUser as CreateUserData,
  CreateUserVariables,
} from "./types/CreateUser";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import UserForm, { FormType } from "components/userForm";
import routes from "routes";
import { CreateUserDto } from "types/graphql-global-types";
import { useErrorHandler, useNavigate } from "utils";

const CreateUser: FC = () => {
  const [createUser, { loading, error }] = useMutation<
    CreateUserData,
    CreateUserVariables
  >(CREATE_USER, {
    refetchQueries: ["GetUserList"],
  });

  useErrorHandler(error);

  const handleSubmit = useCallback(
    async (input: CreateUserDto) => {
      const { errors, data } = await createUser({ variables: { input } });

      if (!errors) {
        notification.success({
          message: "Успех!",
          description: (
            <>
              Пользователь{" "}
              <strong>{idx(data, x => x.srv.user.create.userName)}</strong> был
              успешно создан
            </>
          ),
        });

        navigate(routes.users);
      }
    },
    [createUser]
  );

  const handleBack = useNavigate(routes.users);

  return (
    <Authorize>
      <MainLayout title="Новый пользователь" onBack={handleBack}>
        <Spin spinning={loading}>
          <UserForm onSubmit={handleSubmit} type={FormType.Create} />
        </Spin>
      </MainLayout>
    </Authorize>
  );
};

export default CreateUser;
